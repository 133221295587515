import { RefObject, useEffect } from 'react';
import { handleOutsideClick } from '../utils/utils';

export type UseClickOutside = {
  dataCenterConnectionItemsRef: RefObject<HTMLDivElement>;
  onOutsideClick: VoidFunction;
  openModalName: string;
};

export const useClickOutside = ({
  dataCenterConnectionItemsRef,
  openModalName,
  onOutsideClick,
}: UseClickOutside): void => {
  useEffect(() => {
    const clickHandler = (event: MouseEvent) =>
      handleOutsideClick({
        event,
        ref: dataCenterConnectionItemsRef,
        openModalName,
        onOutsideClick,
      });

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [dataCenterConnectionItemsRef, openModalName, onOutsideClick]);
};
