import React, { ChangeEventHandler, ReactElement } from 'react';
import { Switch } from '../../../common/components/switch';
import './styles.scss';

export type DataCenterConnectionItemProps = {
  dataCenterName: string;
  dataCenterStatus: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  id: string;
  name: string;
  checked: boolean;
};

export function DataCenterConnectionItem({
  dataCenterName,
  dataCenterStatus,
  onChange,
  id,
  name,
  checked,
}: DataCenterConnectionItemProps): ReactElement {
  return (
    <div className="data-center-connection-item">
      <span className="data-center">{dataCenterName}</span>
      <Switch
        label={dataCenterStatus === 'CONNECTED' ? 'Connected' : 'Disconnected'}
        onChange={onChange}
        id={id}
        name={name}
        checked={checked}
      />
    </div>
  );
}
