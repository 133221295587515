import { combineReducers } from 'redux';
import exposReducer, { ExposState } from '../expos/reducer';
import createReducer, { CreateState } from '../create/reducer';
import expoInfoReducer, { IExpoInfoState } from '../info/reducer';
import editReducer, { IEditState } from '../edit/reducer';
import metadataReducer, { MetadataState } from '../metadata/reducer';
import dataCenterReducer, { DataCentersState } from '../datacenters/reducer';

export interface IAdminState {
  edit: IEditState;
  expos: ExposState;
  create: CreateState;
  expoInfo: IExpoInfoState;
  metadata: MetadataState;
  dataCenters: DataCentersState;
}

export default combineReducers<IAdminState>({
  create: createReducer,
  edit: editReducer,
  expoInfo: expoInfoReducer,
  metadata: metadataReducer,
  expos: exposReducer,
  dataCenters: dataCenterReducer,
});
