import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { ErrorCodes, text } from '../../api/kapua/constants';
import {
  refreshAuthTokens,
  swapAuthCodeForTokens,
} from '../../api/sso/cloudSSO';
import { ROUTES } from '../../app/common/constants';
import { ToastSeverity } from '../../app/common/pov-common-ui';
import {
  AuthActionTypes,
  AuthStatusValues,
  buildSSOFullAuthURL,
  clearCodeVerifier,
  config,
  generateCodeChallenge,
  generateCodeVerifier,
  generateStateParam,
  getAccessToken,
  getAndRemoveSessionItem,
  getCurrentURL,
  getIdPRedirectUri,
  getRefreshToken,
  getSearchParamFromURL,
  OauthQueryParams,
  redirectTo,
  removeToken,
  removeTokens,
  replaceURLWithIntendedURL,
  storeCodeVerifier,
  storeSessionItem,
  storeTokens,
} from '../../auth';
import { Actions as CreateActions, CREATE_EXPO } from '../admin/create/actions';
import createExpoWorker from '../admin/create/sagas/create-expo-worker';
import {
  Actions as EditActions,
  CHECK_CAPACITY,
  FETCH_EXPO,
  SAVE_EXPO,
  VALIDATE_CSV,
} from '../admin/edit/actions';
import checkCapacityWorker from '../admin/edit/sagas/check-capacity-worker';
import fetchExpoWorker from '../admin/edit/sagas/fetch-expo-worker';
import saveExpoWorker from '../admin/edit/sagas/save-expo-worker';
import validateCsvWorker from '../admin/edit/sagas/validate-csv-worker';
import {
  Actions as ExposActions,
  DELETE_EXPO,
  END_EXPO,
  FETCH_ACCESS_CODE,
  FETCH_EXPOS,
  PUBLISH_EXPO,
  UNPUBLISH_EXPO,
} from '../admin/expos/actions';
import deleteExpoWorker from '../admin/expos/sagas/delete-expo-worker';
import endExpoWorker from '../admin/expos/sagas/end-expo-worker';
import fetchAccessCodeWorker from '../admin/expos/sagas/fetch-access-code-worker';
import fetchExposWorker from '../admin/expos/sagas/fetch-expos-worker';
import publishExpoWorker from '../admin/expos/sagas/publish-expo-worker';
import unpublishExpoWorker from '../admin/expos/sagas/unpublish-expo-worker';
import {
  Actions as InfoActions,
  APPLY_REBALANCE_CHANGES,
  GET_EXPO_DEMOS,
  GET_EXPO_ENGAGEMENTS,
} from '../admin/info/actions';
import getApplyChangesWorker from '../admin/info/sagas/get-apply-changes-worker';
import getExpoDemosWorker from '../admin/info/sagas/get-expo-demos-worker';
import getExpoEngagementsWorker from '../admin/info/sagas/get-expo-engagements-worker';
import {
  Actions as MetadataActions,
  FETCH_SURVEY_TYPES,
} from '../admin/metadata/actions';
import { fetchMetadataSurveyTypesWorker } from '../admin/metadata/sagas';
import { toastActions } from '../toast/actions';
import { setAuthStatus } from './actions';
import {
  Actions as DataCenterActions,
  FETCH_DATACENTERS,
  FETCH_DATACENTER_TO_CONNECT,
  FETCH_DATACENTER_TO_DISCONNECT,
  DISCONNECT_DATACENTER,
  CONNECT_DATACENTER,
} from '../admin/datacenters/actions';
import {
  connectDataCenterWorker,
  disconnectDataCenterWorker,
  fetchDataCentersWorker,
  fetchDataCenterToConnectWorker,
  fetchDataCenterToDisconnectWorker,
} from '../admin/datacenters/sagas';

type AdminActions =
  | CreateActions
  | EditActions
  | ExposActions
  | InfoActions
  | MetadataActions
  | DataCenterActions;

function* actionsRequiringAuthWorker(action: AdminActions): SagaIterator {
  try {
    yield call(determineActionWorker, action); // exception thrown here
  } catch (e) {
    switch (e.response.status) {
      case ErrorCodes.NotAuthorized:
        yield call(handle401Worker);
        yield call(determineActionWorker, action);
        break;
      case ErrorCodes.ServiceUnavailable:
        yield call(handle503Worker);
        break;
      default:
        yield call(showSomethingWentWrong);
    }
  }
}

export function* authWatchers(): SagaIterator {
  yield fork(startAuthWatcher);
  yield fork(watchActionsRequiringAuth);
}

function* determineActionWorker(action: AdminActions) {
  switch (action.type) {
    case APPLY_REBALANCE_CHANGES:
      yield call(getApplyChangesWorker, action);
      break;
    case CHECK_CAPACITY:
      yield call(checkCapacityWorker);
      break;
    case CREATE_EXPO:
      yield call(createExpoWorker);
      break;
    case DELETE_EXPO:
      yield call(deleteExpoWorker, action);
      break;
    case END_EXPO:
      yield call(endExpoWorker, action);
      break;
    case FETCH_ACCESS_CODE:
      yield call(fetchAccessCodeWorker, action);
      break;
    case FETCH_EXPO:
      yield call(fetchExpoWorker, action);
      break;
    case FETCH_EXPOS:
      yield call(fetchExposWorker);
      break;
    case GET_EXPO_DEMOS:
      yield call(getExpoDemosWorker, action);
      break;
    case GET_EXPO_ENGAGEMENTS:
      yield call(getExpoEngagementsWorker, action);
      break;
    case PUBLISH_EXPO:
      yield call(publishExpoWorker, action);
      break;
    case SAVE_EXPO:
      yield call(saveExpoWorker);
      break;
    case UNPUBLISH_EXPO:
      yield call(unpublishExpoWorker, action);
      break;
    case VALIDATE_CSV:
      yield call(validateCsvWorker);
      break;
    case FETCH_SURVEY_TYPES:
      yield call(fetchMetadataSurveyTypesWorker);
      break;
    case FETCH_DATACENTERS:
      yield call(fetchDataCentersWorker);
      break;
    case FETCH_DATACENTER_TO_DISCONNECT:
      yield call(fetchDataCenterToDisconnectWorker, action);
      break;
    case FETCH_DATACENTER_TO_CONNECT:
      yield call(fetchDataCenterToConnectWorker, action);
      break;
    case DISCONNECT_DATACENTER:
      yield call(disconnectDataCenterWorker, action);
      break;
    case CONNECT_DATACENTER:
      yield call(connectDataCenterWorker, action);
      break;
    default:
  }
}

export function* fullAuthWorker(): SagaIterator {
  const currentURL = yield call(getCurrentURL);
  const stateParam = yield call(generateStateParam);
  const redirectUri = yield call(getIdPRedirectUri);
  if (!currentURL.startsWith(redirectUri)) {
    yield call(storeSessionItem, stateParam, currentURL);
  }
  const codeVerifier = yield call(generateCodeVerifier);
  const codeChallenge = yield call(generateCodeChallenge, codeVerifier);
  yield call(storeCodeVerifier, codeVerifier);
  const fullAuthURL = yield call(
    buildSSOFullAuthURL,
    redirectUri,
    codeChallenge,
    stateParam,
  );
  yield call(redirectTo, fullAuthURL);
}

export function* handle401Worker(): SagaIterator {
  yield put(setAuthStatus(AuthStatusValues.RefreshingTokens));
  yield call(removeToken, config.SSO.ACCESS_TOKEN_NAME);
  const hasRefreshToken = yield call(getRefreshToken);
  if (hasRefreshToken) {
    yield call(refreshAccessTokenWorker);
  } else {
    yield call(fullAuthWorker);
  }
}

export function* handle503Worker(): SagaIterator {
  yield call(showSomethingWentWrong);
}

export function* handleUnexpectedAuthErrorWorker(
  authStatus: AuthStatusValues,
): SagaIterator {
  yield call(removeTokens);
  yield put(setAuthStatus(authStatus));
  yield put(push(ROUTES.PAGE_NOT_FOUND_404));
  yield call(showSomethingWentWrong);
}

export function* hasAccessTokenWorker(): SagaIterator {
  yield put(setAuthStatus(AuthStatusValues.Authenticated));
}

export function* refreshAccessTokenWorker(): SagaIterator {
  const refreshToken = yield call(getRefreshToken);

  try {
    const result = yield call(refreshAuthTokens, refreshToken);

    yield call(
      storeTokens,
      result.data.access_token,
      result.data.refresh_token,
    );
    yield put(setAuthStatus(AuthStatusValues.Authenticated));
  } catch (e) {
    yield call(removeTokens);
    yield call(fullAuthWorker);
  }
}

export function* showSomethingWentWrong(): SagaIterator {
  yield put(
    toastActions.showToast(
      text.error,
      text.somethingWentWrong,
      ToastSeverity.DANGER,
    ),
  );
}

export function* startAuthWatcher(): SagaIterator {
  yield all([
    takeLatest(AuthActionTypes.StartAuth, startAuthWorker),
    takeLatest(AuthActionTypes.SwapAccessCode, swapCodeWorker),
  ]);
}

export function* startAuthWorker(): SagaIterator {
  const hasAccessToken = yield call(getAccessToken);
  if (hasAccessToken) {
    yield call(hasAccessTokenWorker);
  } else {
    const hasRefreshToken = yield call(getRefreshToken);
    if (hasRefreshToken) {
      yield call(refreshAccessTokenWorker);
    } else {
      yield call(fullAuthWorker);
    }
  }
}

export function* swapCodeWorker(): SagaIterator {
  const stateParam = yield call(
    getSearchParamFromURL,
    OauthQueryParams.StateParam,
  );
  const targetUrl: string = yield call(getAndRemoveSessionItem, stateParam);
  const code = yield call(getSearchParamFromURL, OauthQueryParams.AccessCode);

  if (!code || !targetUrl) {
    yield call(
      handleUnexpectedAuthErrorWorker,
      AuthStatusValues.NotAuthenticated,
    );
    return;
  }

  try {
    const redirectUri = yield call(getIdPRedirectUri);
    const result: AxiosResponse<ISSOSwapCodeResponse> = yield call(
      swapAuthCodeForTokens,
      code,
      redirectUri,
    );

    yield call(
      storeTokens,
      result.data.access_token,
      result.data.refresh_token,
    );
    yield call(clearCodeVerifier);
    yield put(setAuthStatus(AuthStatusValues.Authenticated));
    yield call(replaceURLWithIntendedURL, targetUrl);
  } catch (e) {
    yield call(
      handleUnexpectedAuthErrorWorker,
      AuthStatusValues.NotAuthenticated,
    );
  }
}

export function* watchActionsRequiringAuth() {
  const ACTIONS_REQUIRING_AUTH = [
    APPLY_REBALANCE_CHANGES,
    CHECK_CAPACITY,
    CREATE_EXPO,
    DELETE_EXPO,
    END_EXPO,
    FETCH_ACCESS_CODE,
    FETCH_EXPO,
    FETCH_EXPOS,
    GET_EXPO_DEMOS,
    GET_EXPO_ENGAGEMENTS,
    PUBLISH_EXPO,
    SAVE_EXPO,
    UNPUBLISH_EXPO,
    VALIDATE_CSV,
    FETCH_SURVEY_TYPES,
    FETCH_DATACENTERS,
    FETCH_DATACENTER_TO_DISCONNECT,
    FETCH_DATACENTER_TO_CONNECT,
    DISCONNECT_DATACENTER,
    CONNECT_DATACENTER,
  ];
  const requestChannel = yield actionChannel(ACTIONS_REQUIRING_AUTH);
  while (true) {
    const action = yield take(requestChannel);
    yield call(actionsRequiringAuthWorker, action); // IMPORTANT: we're using a blocking call here.
  }
}
