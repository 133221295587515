import {
  IDataCenter,
  IDataCenterToUpdate,
} from '../../../app/entities/expos/types';
import {
  Actions,
  FETCH_DATACENTERS,
  FETCH_DATACENTERS_SUCCESS,
  FETCH_DATACENTER_TO_CONNECT,
  FETCH_DATACENTER_TO_CONNECT_SUCCESS,
  FETCH_DATACENTER_TO_DISCONNECT,
  FETCH_DATACENTER_TO_DISCONNECT_SUCCESS,
  DISCONNECT_DATACENTER,
  DISCONNECT_DATACENTER_SUCCESS,
  CONNECT_DATACENTER,
  CONNECT_DATACENTER_SUCCESS,
  RESET,
} from './actions';

export type DataCentersState = {
  items: IDataCenter[];
  loading: boolean;
  fetched: boolean;
  dataCenterToDisconnect: IDataCenterToUpdate | undefined;
  dataCenterToConnect: IDataCenterToUpdate | undefined;
  isDisconnectingDataCenter: boolean;
  isConnectingDataCenter: boolean;
};

export const initialState: DataCentersState = {
  items: [],
  loading: false,
  fetched: false,
  dataCenterToDisconnect: undefined,
  dataCenterToConnect: undefined,
  isDisconnectingDataCenter: false,
  isConnectingDataCenter: false,
};

export default function dataCenterReducer(
  state: DataCentersState = initialState,
  action: Actions,
): DataCentersState {
  switch (action.type) {
    case FETCH_DATACENTERS:
      return {
        ...state,
        items: [],
        loading: true,
        fetched: false,
      };
    case FETCH_DATACENTERS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        fetched: true,
      };
    case FETCH_DATACENTER_TO_DISCONNECT:
      return {
        ...state,
        isDisconnectingDataCenter: true,
      };
    case FETCH_DATACENTER_TO_DISCONNECT_SUCCESS:
      return {
        ...state,
        dataCenterToDisconnect: action.payload,
      };
    case FETCH_DATACENTER_TO_CONNECT:
      return {
        ...state,
        isConnectingDataCenter: true,
      };
    case FETCH_DATACENTER_TO_CONNECT_SUCCESS:
      return {
        ...state,
        dataCenterToConnect: action.payload,
      };
    case DISCONNECT_DATACENTER:
      return {
        ...state,
        isDisconnectingDataCenter: true,
      };
    case DISCONNECT_DATACENTER_SUCCESS:
      return {
        ...state,
        isDisconnectingDataCenter: false,
        dataCenterToDisconnect: undefined,
      };
    case CONNECT_DATACENTER:
      return {
        ...state,
        isConnectingDataCenter: true,
      };
    case CONNECT_DATACENTER_SUCCESS:
      return {
        ...state,
        isConnectingDataCenter: false,
        dataCenterToConnect: undefined,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
