import React, { ReactElement } from 'react';
import ModalInner from '../modal-inner';
import { Button } from '../../../pov-common-ui';
import { useTranslation } from 'react-i18next';
import { IDataCenterToUpdate } from '../../../../entities/expos/types';

export type ConnectDataCenterProps = {
  dataCenterToConnect: IDataCenterToUpdate | undefined;
  connectDataCenter: (dataCenter: IDataCenterToUpdate) => void;
  closeModal: VoidFunction;
  reset: VoidFunction;
  fetchDatacenters: VoidFunction;
};

const ConnectDataCenter = ({
  dataCenterToConnect,
  connectDataCenter,
  closeModal,
  reset,
  fetchDatacenters,
}: ConnectDataCenterProps): ReactElement => {
  const { t } = useTranslation();

  if (!dataCenterToConnect) return <></>;

  const handleConnectClick = () => connectDataCenter(dataCenterToConnect);

  const handleCancelClick = () => {
    closeModal();
    reset();
    fetchDatacenters();
  };

  return (
    <ModalInner
      title={t('admin.dataCenters.modal.connect.title', {
        dataCenter: dataCenterToConnect.code,
      })}
      content={t('admin.dataCenters.modal.connect.message')}
      buttons={
        <>
          <Button color="secondary" onClick={handleCancelClick}>
            {t('admin.dataCenters.modal.cancel')}
          </Button>
          <Button color="primary" onClick={handleConnectClick}>
            {t('admin.dataCenters.modal.connect.button')}
          </Button>
        </>
      }
    />
  );
};

export default ConnectDataCenter;
