import React, { ReactElement, useRef } from 'react';
import { Button } from '../../../common/pov-common-ui';
import { DataCenterConnectionItemsContainer } from '../data-center-connection-items/container';
import { handleDataCenterConnectionButtonOnClick } from './utils/utils';
import { useClickOutside } from './hooks/use-click-outside';
import { IDataCenter } from '../../../../app/entities/expos/types';
import { IAction } from '../../../../redux/action-helpers';
import i18n from '../../../../i18n';
import './styles.scss';
import {
  FETCH_DATACENTERS,
  RESET,
} from '../../../../redux/admin/datacenters/actions';

export interface IProps {
  fetchDatacenters: () => IAction<typeof FETCH_DATACENTERS>;
  reset: () => IAction<typeof RESET>;
  dataCenters: IDataCenter[];
  openModalName: string;
}

export function DataCenterConnectionButton({
  fetchDatacenters,
  reset,
  dataCenters,
  openModalName,
}: IProps): ReactElement {
  const dataCenterConnectionItemsRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    dataCenterConnectionItemsRef,
    openModalName,
    onOutsideClick: reset,
  });

  const handleOnClick = () => {
    handleDataCenterConnectionButtonOnClick(
      dataCenters,
      fetchDatacenters,
      reset,
    );
  };

  return (
    <div className="data-center-connection-button">
      <Button
        size="small"
        onClick={handleOnClick}
        ariaLabel="Data Center Connection Button"
      >
        {i18n.t('admin.dataCenterConnection')}
      </Button>
      <div ref={dataCenterConnectionItemsRef}>
        <DataCenterConnectionItemsContainer />
      </div>
    </div>
  );
}
