import { RefObject } from 'react';
import { IDataCenter } from '../../../../entities/expos/types';

export const handleDataCenterConnectionButtonOnClick = (
  dataCenters: IDataCenter[],
  fetchDatacenters: VoidFunction,
  reset: VoidFunction,
): void => {
  if (dataCenters.length === 0) {
    fetchDatacenters();
  } else {
    reset();
  }
};

export type HandleOutsideClick = {
  event: MouseEvent;
  ref: RefObject<HTMLDivElement>;
  openModalName: string;
  onOutsideClick: VoidFunction;
};

export const handleOutsideClick = ({
  event,
  ref,
  openModalName,
  onOutsideClick,
}: HandleOutsideClick): void => {
  const target = event.target as HTMLElement;

  const isModalOpen = openModalName !== '';

  if (ref.current && !ref.current.contains(target) && !isModalOpen) {
    onOutsideClick();
  }
};
