export enum MODAL_NAMES {
  ACCESS_CODE = 'ACCESS_CODE',
  ANY_CONNECT = 'ANY_CONNECT',
  DELETE_EXPO = 'DELETE_EXPO',
  DEMO_RESOURCES = 'DEMO_RESOURCES',
  END_ENGAGEMENT = 'END_ENGAGEMENT',
  END_EXPO = 'END_EXPO',
  EXPLORE_DEMO = 'EXPLORE_DEMO',
  EXPO_CHANGED = 'EXPO_CHANGED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  GETTING_STARTED = 'GETTING_STARTED',
  PUBLISH_EXPO = 'PUBLISH_EXPO',
  UNPUBLISH_EXPO = 'UNPUBLISH_EXPO',
  CONNECT_DATA_CENTER = 'CONNECT_DATA_CENTER',
  DISCONNECT_DATA_CENTER = 'DISCONNECT_DATA_CENTER',
}
