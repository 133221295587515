import React, { ReactElement } from 'react';
import ModalInner from '../modal-inner';
import { Button } from '../../../pov-common-ui';
import { useTranslation } from 'react-i18next';
import { IDataCenterToUpdate } from '../../../../entities/expos/types';

export type DisconnectDataCenterProps = {
  dataCenterToDisconnect: IDataCenterToUpdate | undefined;
  disconnectDataCenter: (dataCenter: IDataCenterToUpdate) => void;
  closeModal: VoidFunction;
  reset: VoidFunction;
  fetchDatacenters: VoidFunction;
};

const DisconnectDataCenter = ({
  dataCenterToDisconnect,
  disconnectDataCenter,
  closeModal,
  reset,
  fetchDatacenters,
}: DisconnectDataCenterProps): ReactElement => {
  const { t } = useTranslation();

  if (!dataCenterToDisconnect) return <></>;

  const handleDisconnectClick = () =>
    disconnectDataCenter(dataCenterToDisconnect);

  const handleCancelClick = () => {
    closeModal();
    reset();
    fetchDatacenters();
  };

  return (
    <ModalInner
      title={t('admin.dataCenters.modal.disconnect.title', {
        dataCenter: dataCenterToDisconnect.code,
      })}
      content={t('admin.dataCenters.modal.disconnect.message')}
      buttons={
        <>
          <Button color="secondary" onClick={handleCancelClick}>
            {t('admin.dataCenters.modal.cancel')}
          </Button>
          <Button color="primary" onClick={handleDisconnectClick}>
            {t('admin.dataCenters.modal.disconnect.button')}
          </Button>
        </>
      }
    />
  );
};

export default DisconnectDataCenter;
