import classnames from 'classnames';
import React, { InputHTMLAttributes, ReactElement } from 'react';

export type SizeTypes = 'default' | 'large';

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export function Switch({
  label,
  checked,
  id,
  required,
  disabled = false,
  ...rest
}: SwitchProps): ReactElement {
  const classes = classnames('switch', {
    disabled,
  });

  return (
    <label className={classes}>
      <input
        aria-labelledby={id}
        type="checkbox"
        aria-checked={checked}
        disabled={disabled}
        checked={checked}
        {...rest}
      />
      <span className="switch__input" role="checkbox" aria-checked={checked} />
      <span className="switch__label" id={id}>
        {label}
        {required && <sup>*</sup>}
      </span>
    </label>
  );
}
