import { connect } from 'react-redux';
import { datacentersActions } from '../../../../../redux/admin/datacenters/actions';
import { IApplicationState } from '../../../../../redux/types';
import { getDataCenterToConnect } from '../../../../../redux/admin/datacenters/selectors';
import ConnectDataCenter from '.';

const mapStateToProps = (state: IApplicationState) => ({
  dataCenterToConnect: getDataCenterToConnect(state),
});

const mapDispatchToProps = {
  connectDataCenter: datacentersActions.connectDataCenter,
  fetchDatacenters: datacentersActions.fetchDatacenters,
  reset: datacentersActions.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectDataCenter);
