import { connect } from 'react-redux';
import { getDataCenters } from '../../../../redux/admin/datacenters/selectors';
import { IApplicationState } from '../../../../redux/types';
import { DataCenterConnectionItems } from './';

const mapStateToProps = (state: IApplicationState) => ({
  dataCenters: getDataCenters(state),
});

export const DataCenterConnectionItemsContainer = connect(mapStateToProps)(
  DataCenterConnectionItems,
);
