import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  CONNECT_DATACENTER,
  datacentersActions,
  DISCONNECT_DATACENTER,
  FETCH_DATACENTERS,
  FETCH_DATACENTER_TO_CONNECT,
  FETCH_DATACENTER_TO_DISCONNECT,
} from './actions';
import {
  getDataCenter,
  getDataCenters,
  updateDataCenter,
} from '../../../app/entities/expos/service';
import {
  IDataCenter,
  IDataCenterToUpdate,
} from '../../../app/entities/expos/types';
import { modalActions } from '../../../redux/modals/actions';
import { buildDataCenterUpdateRequest } from '../../../app/entities/expos/utilities';
import { FLICKER_DELAY } from '../../../app/common/constants';
import { toastActions } from '../../../redux/toast/actions';
import { ToastSeverity } from '../../../app/common/pov-common-ui';
import { IActionWithPayload } from '../../../redux/action-helpers';
import { AxiosResponse } from 'axios';

export function* fetchDataCentersWorker(): SagaIterator {
  try {
    const response: IDataCenter[] = yield call(getDataCenters);
    yield put(datacentersActions.fetchDatacentersSuccess(response));
  } catch (e) {
    throw e;
  }
}

export function* fetchDataCenterToDisconnectWorker(
  action: IActionWithPayload<typeof FETCH_DATACENTER_TO_DISCONNECT, string>,
): SagaIterator {
  try {
    const {
      headers: { etag },
      data,
    }: AxiosResponse<IDataCenter> = yield call(getDataCenter, action.payload);

    yield put(
      datacentersActions.fetchDataCenterToDisconnectSuccess({ ...data, etag }),
    );
  } catch (e) {
    yield put(modalActions.closeModal());
    yield put(datacentersActions.reset());
    throw e;
  }
}

export function* fetchDataCenterToConnectWorker(
  action: IActionWithPayload<typeof FETCH_DATACENTER_TO_CONNECT, string>,
): SagaIterator {
  try {
    const {
      headers: { etag },
      data,
    }: AxiosResponse<IDataCenter> = yield call(getDataCenter, action.payload);

    yield put(
      datacentersActions.fetchDataCenterToConnectSuccess({ ...data, etag }),
    );
  } catch (e) {
    yield put(modalActions.closeModal());
    yield put(datacentersActions.reset());
    throw e;
  }
}

export function* disconnectDataCenterWorker(
  action: IActionWithPayload<typeof DISCONNECT_DATACENTER, IDataCenterToUpdate>,
): SagaIterator {
  const dataCenter = action.payload;
  const { etag = '' } = dataCenter;

  try {
    yield put(modalActions.modalLoading(true));
    const payload = yield call(
      buildDataCenterUpdateRequest,
      dataCenter,
      'DISCONNECTED',
    );
    yield call(updateDataCenter, payload, etag);
    yield delay(FLICKER_DELAY);
    yield put(datacentersActions.disconnectDataCenterSuccess());
    yield put(
      toastActions.showToast(
        'Success',
        `${dataCenter.code} Data Center Disconnected!`,
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield call(fetchDataCentersWorker);
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}

export function* connectDataCenterWorker(
  action: IActionWithPayload<typeof CONNECT_DATACENTER, IDataCenterToUpdate>,
): SagaIterator {
  const dataCenter = action.payload;
  const { etag = '' } = dataCenter;

  try {
    yield put(modalActions.modalLoading(true));
    const payload = yield call(
      buildDataCenterUpdateRequest,
      dataCenter,
      'CONNECTED',
    );
    yield call(updateDataCenter, payload, etag);
    yield delay(FLICKER_DELAY);
    yield put(datacentersActions.connectDataCenterSuccess());
    yield put(
      toastActions.showToast(
        'Success',
        `${dataCenter.code} Data Center Connected!`,
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield call(fetchDataCentersWorker);
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}

export default function* datacentersWatcher(): SagaIterator {
  yield all([
    takeLatest(FETCH_DATACENTERS, fetchDataCentersWorker),
    takeLatest(FETCH_DATACENTER_TO_CONNECT, fetchDataCenterToConnectWorker),
    takeLatest(
      FETCH_DATACENTER_TO_DISCONNECT,
      fetchDataCenterToDisconnectWorker,
    ),
    takeEvery(DISCONNECT_DATACENTER, disconnectDataCenterWorker),
    takeEvery(CONNECT_DATACENTER, connectDataCenterWorker),
  ]);
}
