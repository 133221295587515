import { connect } from 'react-redux';
import { datacentersActions } from '../../../../../redux/admin/datacenters/actions';
import { IApplicationState } from '../../../../../redux/types';
import { getDataCenterToDisconnect } from '../../../../../redux/admin/datacenters/selectors';
import DisconnectDataCenter from '.';

const mapStateToProps = (state: IApplicationState) => ({
  dataCenterToDisconnect: getDataCenterToDisconnect(state),
});

const mapDispatchToProps = {
  disconnectDataCenter: datacentersActions.disconnectDataCenter,
  fetchDatacenters: datacentersActions.fetchDatacenters,
  reset: datacentersActions.reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisconnectDataCenter);
