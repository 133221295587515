import {
  IDataCenter,
  IDataCenterToUpdate,
} from '../../../app/entities/expos/types';
import { IApplicationState } from '../../types';
import { DataCentersState } from './reducer';

export const getDataCentersState = (
  state: IApplicationState,
): DataCentersState => state.admin.dataCenters;

export const getDataCenters = (state: IApplicationState): IDataCenter[] =>
  getDataCentersState(state).items;

export const getDataCentersFetched = (state: IApplicationState): boolean =>
  getDataCentersState(state).fetched;

export const getDataCentersLoading = (state: IApplicationState): boolean =>
  getDataCentersState(state).loading;

export const getDataCenterToDisconnect = (
  state: IApplicationState,
): IDataCenterToUpdate | undefined =>
  getDataCentersState(state).dataCenterToDisconnect;

export const getDataCenterToConnect = (
  state: IApplicationState,
): IDataCenterToUpdate | undefined =>
  getDataCentersState(state).dataCenterToConnect;

export const getIsDisconnetingDataCetner = (
  state: IApplicationState,
): boolean => getDataCentersState(state).isDisconnectingDataCenter;

export const getIsConnetingDataCetner = (state: IApplicationState): boolean =>
  getDataCentersState(state).isConnectingDataCenter;
