import {
  createAction,
  IAction,
  IActionWithPayload,
} from '../../action-helpers';
import { ActionsUnion } from '../../action-types';
import {
  IDataCenter,
  IDataCenterToUpdate,
} from '../../../app/entities/expos/types';

export const FETCH_DATACENTERS = 'ADMIN/DATA_CENTERS/FETCH_DATACENTERS';
export const FETCH_DATACENTERS_SUCCESS =
  'ADMIN/DATA_CENTERS/FETCH_DATACENTERS_SUCCESS';
export const FETCH_DATACENTER_TO_CONNECT =
  'ADMIN/DATA_CENTERS/FETCH_DATACENTER_TO_CONNECT';
export const FETCH_DATACENTER_TO_CONNECT_SUCCESS =
  'ADMIN/DATA_CENTERS/FETCH_DATACENTER_TO_CONNECT_SUCCESS';
export const FETCH_DATACENTER_TO_DISCONNECT =
  'ADMIN/DATA_CENTERS/FETCH_DATACENTER_TO_DISCONNECT';
export const FETCH_DATACENTER_TO_DISCONNECT_SUCCESS =
  'ADMIN/DATA_CENTERS/FETCH_DATACENTER_TO_DISCONNECT_SUCCESS';
export const DISCONNECT_DATACENTER = 'ADMIN/DATA_CENTERS/DISCONNECT_DATACENTER';
export const DISCONNECT_DATACENTER_SUCCESS =
  'ADMIN/DATA_CENTERS/DISCONNECT_DATACENTER_SUCCESS';
export const CONNECT_DATACENTER = 'ADMIN/DATA_CENTERS/CONNECT_DATACENTER';
export const CONNECT_DATACENTER_SUCCESS =
  'ADMIN/DATA_CENTERS/CONNECT_DATACENTER_SUCCESS';
export const RESET = 'ADMIN/DATA_CENTERS/RESET';

export const fetchDatacenters = (): IAction<typeof FETCH_DATACENTERS> =>
  createAction(FETCH_DATACENTERS);

export const fetchDatacentersSuccess = (
  dataCenters: IDataCenter[],
): IActionWithPayload<typeof FETCH_DATACENTERS_SUCCESS, IDataCenter[]> =>
  createAction(FETCH_DATACENTERS_SUCCESS, dataCenters);

const fetchDataCenterToConnect = (
  uid: string,
): IActionWithPayload<typeof FETCH_DATACENTER_TO_CONNECT, string> =>
  createAction(FETCH_DATACENTER_TO_CONNECT, uid);

const fetchDataCenterToConnectSuccess = (
  dataCenter: IDataCenterToUpdate,
): IActionWithPayload<
  typeof FETCH_DATACENTER_TO_CONNECT_SUCCESS,
  IDataCenterToUpdate
> => createAction(FETCH_DATACENTER_TO_CONNECT_SUCCESS, dataCenter);

const fetchDataCenterToDisconnect = (
  uid: string,
): IActionWithPayload<typeof FETCH_DATACENTER_TO_DISCONNECT, string> =>
  createAction(FETCH_DATACENTER_TO_DISCONNECT, uid);

const fetchDataCenterToDisconnectSuccess = (
  dataCenter: IDataCenterToUpdate,
): IActionWithPayload<
  typeof FETCH_DATACENTER_TO_DISCONNECT_SUCCESS,
  IDataCenterToUpdate
> => createAction(FETCH_DATACENTER_TO_DISCONNECT_SUCCESS, dataCenter);

export const disconnectDataCenter = (
  dataCenter: IDataCenterToUpdate,
): IActionWithPayload<typeof DISCONNECT_DATACENTER, IDataCenterToUpdate> =>
  createAction(DISCONNECT_DATACENTER, dataCenter);

export const disconnectDataCenterSuccess = (): IAction<
  typeof DISCONNECT_DATACENTER_SUCCESS
> => createAction(DISCONNECT_DATACENTER_SUCCESS);

export const connectDataCenter = (
  dataCenter: IDataCenterToUpdate,
): IActionWithPayload<typeof CONNECT_DATACENTER, IDataCenterToUpdate> =>
  createAction(CONNECT_DATACENTER, dataCenter);

export const connectDataCenterSuccess = (): IAction<
  typeof CONNECT_DATACENTER_SUCCESS
> => createAction(CONNECT_DATACENTER_SUCCESS);

const reset = (): IAction<typeof RESET> => createAction(RESET);

export const datacentersActions = {
  fetchDatacenters,
  fetchDatacentersSuccess,
  fetchDataCenterToConnect,
  fetchDataCenterToConnectSuccess,
  fetchDataCenterToDisconnect,
  fetchDataCenterToDisconnectSuccess,
  disconnectDataCenter,
  disconnectDataCenterSuccess,
  connectDataCenter,
  connectDataCenterSuccess,
  reset,
};

export type Actions = ActionsUnion<typeof datacentersActions>;
