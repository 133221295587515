import React, { ReactElement } from 'react';
import { DataCenterConnectionItem } from '../data-center-connection-item';
import { IDataCenter } from '../../../../app/entities/expos/types';
import './styles.scss';
import { toggleDataCenterConnectivity } from './utils/utils';

export interface IProps {
  dataCenters: IDataCenter[];
}

export function DataCenterConnectionItems({
  dataCenters,
}: IProps): ReactElement {
  if (dataCenters.length === 0) {
    return <></>;
  }

  return (
    <div className="data-center-connection-items">
      {dataCenters.map((dataCenter) => {
        return (
          <DataCenterConnectionItem
            dataCenterName={dataCenter.code}
            dataCenterStatus={dataCenter.connectivity}
            onChange={(event) =>
              toggleDataCenterConnectivity(event, dataCenter)
            }
            id={dataCenter.code}
            name={dataCenter.code}
            checked={dataCenter.connectivity === 'CONNECTED'}
            key={dataCenter.uid}
          />
        );
      })}
    </div>
  );
}
