import { connect } from 'react-redux';
import { getDataCenters } from '../../../../redux/admin/datacenters/selectors';
import { IApplicationState } from '../../../../redux/types';
import { datacentersActions } from '../../../../redux/admin/datacenters/actions';
import { DataCenterConnectionButton } from '.';
import { getOpenModalName } from '../../../../redux/modals/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  dataCenters: getDataCenters(state),
  openModalName: getOpenModalName(state),
});

const mapDispatchToProps = {
  fetchDatacenters: datacentersActions.fetchDatacenters,
  reset: datacentersActions.reset,
};

export const DataCenterConnectionButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataCenterConnectionButton);
