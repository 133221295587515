import { ChangeEvent } from 'react';
import { IDataCenter } from '../../../../entities/expos/types';
import { datacentersActions } from '../../../../../redux/admin/datacenters/actions';
import { modalActions } from '../../../../../redux/modals/actions';
import { MODAL_NAMES } from '../../../../common/components/modal/constants';
import store from '../../../../../redux/store';

export const toggleDataCenterConnectivity = (
  event: ChangeEvent<HTMLInputElement>,
  dataCenter: IDataCenter,
): void => {
  const { uid } = dataCenter;
  if (event.target.checked) {
    store.dispatch(datacentersActions.fetchDataCenterToConnect(uid));
    store.dispatch(modalActions.openModal(MODAL_NAMES.CONNECT_DATA_CENTER));
  } else {
    store.dispatch(datacentersActions.fetchDataCenterToDisconnect(uid));
    store.dispatch(modalActions.openModal(MODAL_NAMES.DISCONNECT_DATA_CENTER));
  }
};
